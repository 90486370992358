import { gql } from '@apollo/client';

export const NEWSLETTER_FORM_FIELDS = gql`
    query {
        newsletterFormFields {
            genders {
                id
                value
            }
            sports {
                id
                value
            }
            ageGroups {
                id
                value
            }
        }
    }
`;

export const NEWSLETTER_COUNTRIES_FORM_FIELD = gql`
    query {
        newsletterFormFields {
            countries {
                id
                value
                code
            }
        }
    }
`;

export const SUBSCRIBE_NEWSLETTER_MUTATION = gql`
    mutation subscribeEmailToNewsletter($email: String!, $source: SubscriptionSource) {
        subscribeEmailToNewsletter(email: $email, source: $source) @connection(key: "subscribeEmailToNewsletter") {
            status
        }
    }
`;

export const CONFIRM_NEWSLETTER_SUBSCRIPTION_MUTATION = gql`
    mutation subscribeEmailToNewsletter($id: String!, $code: String!) {
        confirmSubscriber(id: $id, code: $code) {
            status
        }
    }
`;

export const UPDATE_NEWSLETTER_MUTATION = gql`
    mutation updateSubscriptionByEmail(
        $email: String!
        $firstName: String
        $lastName: String
        $countryId: Int!
        $genderId: Int
        $ageGroup: String
        $sports: String
    ) {
        updateSubscriptionByEmail(
            input: {
                email: $email
                firstName: $firstName
                lastName: $lastName
                countryId: $countryId
                genderId: $genderId
                ageGroup: $ageGroup
                sports: $sports
            }
        ) @connection(key: "updateSubscriptionByEmail") {
            result
        }
    }
`;

export default {
    mutations: {
        confirmNewsletterSubscriptionMutation: CONFIRM_NEWSLETTER_SUBSCRIPTION_MUTATION,
        subscribeNewsletterMutation: SUBSCRIBE_NEWSLETTER_MUTATION,
        updateNewsletterMutation: UPDATE_NEWSLETTER_MUTATION,
    },
    queries: {
        newsletterFormFields: NEWSLETTER_FORM_FIELDS,
    },
};
