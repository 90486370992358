'use client';

import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { usePathname } from 'next/navigation';
import Link from '@/components/Link';
import SubMenuWrapper from './modules/submenuWrapper';
import type { IMegaMenuItemProps } from './types';
import clsx from 'clsx';
import classes from './megaMenuItem.module.scss';

const MegaMenuItem = ({ category }: IMegaMenuItemProps): ReactElement => {
    const {
        children: categoryChildren,
        menu_item_link: menuItemLink,
        name,
        url_path: urlPath,
        url_suffix: urlSuffix,
    } = category || {};
    const resourceUrl = useResourceUrl();
    const pathname = usePathname();

    const categoryUrl = resourceUrl(`${menuItemLink?.replace('{{base_url}}', '/') || `/${urlPath}`}${urlSuffix || ''}`);
    const [isItemHoveredOver, setIsItemHoveredOver] = useState(false);

    const handleItemUnhover = useCallback(() => {
        sessionStorage.setItem('intentViewItemList', 'positive');
        setIsItemHoveredOver(false);
    }, []);

    const rootClassName = clsx(
        classes.megaMenuItem,
        isItemHoveredOver ? classes.isItemHoveredOver : classes.megaMenuItem,
    );

    const isMainCategoryActive = pathname.split('/').includes(urlPath);

    const categoryLink = useMemo(() => {
        const children = <span className={categoryChildren ? classes.megaMenuLinkText : ''}>{name}</span>;
        const commonProps = {
            className: clsx(classes.megaMenuLink, isMainCategoryActive && classes.megaMenuLinkActive),
            href: categoryUrl,
            onClick: handleItemUnhover,
        };

        return <Link {...commonProps}>{children}</Link>;
    }, [categoryUrl, name, isMainCategoryActive]);

    return (
        <div
            className={rootClassName}
            onMouseOver={() => setIsItemHoveredOver(true)}
            onMouseOut={() => setIsItemHoveredOver(false)}
            data-mt-type="mega-menu-item"
        >
            {categoryLink}
            <SubMenuWrapper
                items={categoryChildren}
                setIsItemHoveredOver={setIsItemHoveredOver}
                shouldRenderSubmenu={isItemHoveredOver}
            />
        </div>
    );
};

export default MegaMenuItem;
