import { useLazyQuery, useMutation } from '@apollo/client';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import subscribeNewsletterOperations, {
    NEWSLETTER_COUNTRIES_FORM_FIELD,
} from '@/components/Newsletter/subscribeNewsletter.gql';
import { trimInput } from '@/components/TextInput';
import { useFormSubmit } from '@vaimo/google-tag-manager';
import { StepEnum, type TNewsletterSetState } from '@/components/Newsletter/types';
import type { IFormSubscribeFields, IUseFormSubscribe } from '@/components/Newsletter/FormSubscribe/types';
import { useEffect } from 'react';

export const useFormSubscribe = ({ setStep }: { setStep: TNewsletterSetState }): IUseFormSubscribe => {
    const { submitFormToDataLayer } = useFormSubmit();
    const [subscribeNewsletter, { error: subscribeError }] = useMutation(
        subscribeNewsletterOperations.mutations.subscribeNewsletterMutation,
    );
    const [updateNewsletter, { error: updateError }] = useMutation(
        subscribeNewsletterOperations.mutations.updateNewsletterMutation,
    );

    const { refetch: getFormData } = useQuery(subscribeNewsletterOperations.queries.newsletterFormFields, {
        skip: true,
    });

    const [getCountries, { data: countriesData }] = useLazyQuery(NEWSLETTER_COUNTRIES_FORM_FIELD);

    const handleSubmit = async ({ context, countryId, email }: IFormSubscribeFields) => {
        try {
            await subscribeNewsletter({
                context,
                variables: { email: trimInput(email), source: 'FOOTER' },
            });
            await updateNewsletter({ context, variables: { countryId, email: trimInput(email) } });

            const { data } = await getFormData();

            setStep({
                currentStep: StepEnum.UPDATE,
                data: {
                    ...data.newsletterFormFields,
                    countries: countriesData?.newsletterFormFields.countries,
                    countryId,
                },
                email,
            });
            submitFormToDataLayer({ formName: 'newsletter' });
        } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        getCountries();
    }, []);

    return {
        countries: countriesData?.newsletterFormFields.countries ?? [],
        error: subscribeError || updateError,
        handleSubmit,
    };
};
