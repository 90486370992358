'use client';

import React, { ReactElement, Suspense } from 'react';
import { ISubmenuProps } from '../types';
import classes from './../submenu.module.scss';

const Submenu = React.lazy(() => import('@/components/MegaMenu/submenu'));

const SubMenuWrapper = ({ items, setIsItemHoveredOver, shouldRenderSubmenu }: ISubmenuProps): ReactElement => {
    const children =
        items.length > 0 ? (
            <Suspense fallback={null}>
                <Submenu
                    items={items}
                    setIsItemHoveredOver={setIsItemHoveredOver}
                    shouldRenderSubmenu={shouldRenderSubmenu}
                />
            </Suspense>
        ) : null;

    return <div className={classes.submenu}>{children}</div>;
};

export default SubMenuWrapper;
